@media screen and (max-width:768px) {
  .navbar-nav{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction:row!important;
    li{
     // display: block;
      width: 49%;
      margin: 0 1%;
      text-align: center;
    }
  }
  .navbar-light .navbar-brand{
    display: block;
    text-align: center;
    margin: 0 auto;
}
.fixed-top{
  position: sticky!important;
  position:-webkit-sticky!important;
}
  

}

@media (min-width: 992px){
.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-self: flex-start;
    justify-items: right;
    margin-left: auto;
    width: 200px;
}
.fixed-top{
  position: relative!important;
}

.navbar-nav{
  li{ width: 49%;
    margin: 0 1%;
    text-align: center;}
}
}


// Border
@mixin border($border: 1px solid $black-opacity-54) {
  border: $border;
}

// Border-Radius
@mixin border-radius($values) {
  @if $values == default {
    $values: 3px;
  }
  -webkit-border-radius: $values;
  border-radius: $values;
}

// Padding
@mixin padding($padding) {
  padding: $padding;
}

.inputfield {
  label {
    transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
  }
}

.inputfield {
  position: relative;
  margin-bottom: 20px;
  i {
    position: absolute;
    top: 16px;
    right: 14px;
    font-size: 20px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="number"],
  input[type="date"],
  select {
    background: rgba(255, 255, 255, 1);
    -webkit-appearance: none;
    text-shadow: 0 0 0 #24272c;
    @include border(solid 2px rgba(#24272c, 0.1));
    @include border-radius(8px);
    -webkit-text-fill-color: transparent;
    @include padding(13px 30px 0 16px);
    font-size: 16px - 1;
    height: 10px * 5+2;
    width: 100%;
    box-shadow: none;

    &:hover,
    &:focus {
      outline: none;
    }
    &:focus {
      border: solid 2px #000;
      & ~ label {
        color: #24272c;
        font-size: 10px;
        top: 8px;
      }
    }
  }
}

label {
	color: rgba(#24272c,.50); 
	font-size: 16px - 1; 
	position: absolute; 
	pointer-events: none; 
	left: 16px; 
	top: 15px;
}

.error { 
	position: absolute; 
	top: -19px; 
	right: 0px; 
	font-size: 12px; 
	color: #ff1e3c; 
}

label.active{
	color: #24272c;
	font-size: 10px;
	top: 8px;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap");

* {
  box-sizing: border-box;
}
body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
 // height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // text-align: left;
  margin-top: 40px;
  
}

.auth-inner {
 max-width: 450px;
 width:95%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding:20px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.mainbox {
  margin: auto;
  background-color: #ffffff;
  margin: auto;
  height: 400px;
  width: 600px;
  border-radius: 15px;
  position: relative;
}

.err {
  color: #000000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 8rem;
  position: absolute;
  left: 20%;
  top: 8%;
}

.far {
  position: absolute;
  font-size: 5.5rem;
  left: 42%;
  top: 15%;
  color: #000;
}

.err2 {
  color: #000;
  font-family: "Nunito Sans", sans-serif;
  font-size: 8rem;
  position: absolute;
  left: 68%;
  top: 8%;
}

.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.2rem;
  position: absolute;
  left: 16%;
  top: 55%;
  width: 75%;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
}


